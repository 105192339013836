<template>
    <div class="CreateAndEdit">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}仪器仪表
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="deviceInfo" :rules="rules" ref="deviceInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" v-if="state" class="wh-input">
                        <el-input v-model="deviceInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="仪器条码" :label-width="formLabelWidth" prop="deviceCode" >
                        <el-input v-model="deviceInfo.deviceCode" autocomplete="off" maxlength="10"  class="wh-input" placeholder="请录入仪器条码"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器名称" :label-width="formLabelWidth" prop="deviceNameCode">
                        <el-select placeholder="请选择仪器名称" v-model="deviceInfo.deviceNameCode">
                            <el-option v-for="item in deviceNameList" :key="item.code" :label="item.realName"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="实际名称" :label-width="formLabelWidth" prop="deviceRealName"  style="width: 600px;">
                        <el-input v-model="deviceInfo.deviceRealName" autocomplete="off" maxlength="25" show-word-limit  placeholder="请录入仪器实际名称" ></el-input>
                    </el-form-item>
                    <el-form-item label="仪器分类" :label-width="formLabelWidth" prop="optionValue">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-cascader v-model="deviceInfo.optionValue" :options="deviceClassList" :props="Props"
                            :show-all-levels="false"></el-cascader>
                    </el-form-item>
                    <el-form-item label="仪器型号" :label-width="formLabelWidth" prop="deviceType" style="width: 600px;">
                        <el-input v-model="deviceInfo.deviceType" autocomplete="off"  maxlength="40" show-word-limit  placeholder="请录入仪器型号"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器编号" :label-width="formLabelWidth" prop="deviceNum" style="width: 600px;">
                        <el-input v-model="deviceInfo.deviceNum" autocomplete="off" maxlength="40" show-word-limit placeholder="请录入仪器编号"></el-input>
                    </el-form-item>
                    <el-form-item label="购置日期" :label-width="formLabelWidth" prop="purchaseDate">
                        <el-date-picker v-model="deviceInfo.purchaseDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="生产厂家" :label-width="formLabelWidth" prop="manufacturer" style="width: 600px;">
                        <el-input v-model="deviceInfo.manufacturer" autocomplete="off" placeholder="请录入生产厂家" maxlength="50" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="详情图片" :label-width="formLabelWidth" prop="deviceinfoImg">
                        <div class="bannerbox">
                            <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                </uploadImg>
                            </div>
                            <div class="upload1" v-if="imageView">
                                <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                    :http-request="handleUploadFile">
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">点击上传
                                    </div>
                                </el-upload>
                            </div>

                        </div>
                    </el-form-item>
                    <el-form-item label="是否合格" :label-width="formLabelWidth" prop="isCheckoutOk">
                        <el-radio v-model="isCheckoutOk" :label=0>是</el-radio>
                        <el-radio v-model="isCheckoutOk" :label=1>否</el-radio>
                    </el-form-item>
                    <el-form-item :label="tagLabel" :label-width="formLabelWidth" >
                        <uploadImg2 :imageUrl="deviceInfo.xyTag" @imgVal2="updateImg($event, 'xyTag')" ></uploadImg2>
                    </el-form-item>
                    <!-- <el-form-item :label="cerLabel" :label-width="formLabelWidth" required prop="xyCer">
                        <uploadImg2 :imageUrl="deviceInfo.xyCer" @imgVal2="updateImg($event, 'xyCer')" />
                    </el-form-item> -->
                    <el-form-item :label="cerLabel" :label-width="formLabelWidth"  class="input-upload">
                        <el-input v-model="deviceInfo.xyCer" autocomplete="off"  placeholder="选择pdf文件上传"></el-input>
                        <input type="file" ref="import" style="display: none" @change="uploadIn($event)" />
                        <el-button type="info" plain @click="handleIn" style="margin-left: 10px;">选择文件</el-button>
                    </el-form-item>

                    <el-form-item label="仪器状态" :label-width="formLabelWidth" prop="deviceStatus">
                        <el-select placeholder="请选择仪器名称" v-model="deviceInfo.deviceStatus" disabled>
                            <el-option v-for="item in deviceStatusList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>

                    <!-- <el-form-item label="是否需要校验" :label-width="formLabelWidth" prop="isCheck">
                        <el-radio v-model="deviceInfo.isCheck" :label=0>需要</el-radio>
                        <el-radio v-model="deviceInfo.isCheck" :label=1>不需要</el-radio>
                    </el-form-item> -->
                    <!-- <div v-if="deviceInfo.isCheck == 0"> -->
                        <el-form-item label="上次校验日期" :label-width="formLabelWidth" prop="lastCheckDate">
                            <el-date-picker v-model="deviceInfo.lastCheckDate" format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="校验周期（天）" :label-width="formLabelWidth" prop="checkCycle">
                            <el-input type="number" v-model="deviceInfo.checkCycle" class="wh-input"
                                autocomplete="off" placeholder="请输入校验周期"></el-input>
                        </el-form-item>
                    <!-- </div> -->
                    <!-- <el-form-item label="是否需要保养" :label-width="formLabelWidth" prop="isMaintain">
                        <el-radio v-model="deviceInfo.isMaintain" :label=0>需要</el-radio>
                        <el-radio v-model="deviceInfo.isMaintain" :label=1>不需要</el-radio>
                    </el-form-item> -->
                    <!-- <div v-if="deviceInfo.isMaintain == 0"> -->
                    <el-form-item label="上次保养日期" :label-width="formLabelWidth" prop="lastMaintainDate">
                        <el-date-picker v-model="deviceInfo.lastMaintainDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="保养周期(天)" :label-width="formLabelWidth" prop="maintainCycle">
                        <el-input type="number" v-model="deviceInfo.maintainCycle" class="wh-input"
                            autocomplete="off" placeholder="请输入保养周期"></el-input>
                    </el-form-item>
                    <!-- </div> -->
                    <el-form-item label="上次维修日期" :label-width="formLabelWidth" prop="lastWxDate">
                        <el-date-picker v-model="deviceInfo.lastWxDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="仪器功能" :label-width="formLabelWidth" prop="deviceFun" style="width: 600px;">
                        <el-input v-model="deviceInfo.deviceFun" maxlength="100" show-word-limit autocomplete="off"
                            class="input_num" placeholder="请输入仪器功能"></el-input>
                    </el-form-item>
                    <el-form-item label="所在库房" :label-width="formLabelWidth" prop="whCode">
                        <el-select placeholder="请选择库房" v-model="deviceInfo.whCode">
                            <el-option v-for="item in houseList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="保存地点" :label-width="formLabelWidth" prop="location" style="width: 600px;">
                        <el-input v-model="deviceInfo.location" autocomplete="off" placeholder="请输入保存地点" maxlength="50" show-word-limit ></el-input>
                    </el-form-item>
                    <el-form-item label="来源" :label-width="formLabelWidth" prop="source">
                        <el-radio v-model="deviceInfo.source" :label=0>新增</el-radio>
                        <el-radio v-model="deviceInfo.source" :label=1>借入</el-radio>
                        <el-radio v-model="deviceInfo.source" :label=2>租聘</el-radio>
                    </el-form-item>
                    <div v-if="deviceInfo.source == 0">
                        <el-form-item label="购买人" :label-width="formLabelWidth" prop="buyer">
                            <el-input v-model="deviceInfo.buyer" autocomplete="off" placeholder="请输入购买人" maxlength="20" show-word-limit style="width: 220px;"></el-input>
                        </el-form-item>
                        <el-form-item label="购买金额" :label-width="formLabelWidth" prop="purchasePrice">
                            <el-input type="number" v-model="deviceInfo.purchasePrice" class="wh-input"
                                autocomplete="off" placeholder="请输入购买金额" ></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="deviceInfo.source == 1">
                        <el-form-item label="借出方" :label-width="formLabelWidth" prop="lender">
                            <el-input v-model="deviceInfo.lender" autocomplete="off" placeholder="请输入借出方" maxlength="20" show-word-limit style="width: 220px;"></el-input>
                        </el-form-item>
                        <el-form-item label="借入人" :label-width="formLabelWidth" prop="borrower">
                            <el-input v-model="deviceInfo.borrower" autocomplete="off" placeholder="请输入借入人" maxlength="20" show-word-limit style="width: 220px;"></el-input>
                        </el-form-item>
                        <el-form-item label="借入时间" :label-width="formLabelWidth" prop="borrowDate">
                            <el-date-picker v-model="deviceInfo.borrowDate" format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd" type="date" placeholder="选择借入时间">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div v-if="deviceInfo.source == 2">
                        <el-form-item label="租赁方" :label-width="formLabelWidth" prop="leasee" >
                            <el-input v-model="deviceInfo.leasee" autocomplete="off" placeholder="请输入租赁方"  maxlength="20" show-word-limit style="width: 220px;"></el-input>
                        </el-form-item>
                        <el-form-item label="租赁人" :label-width="formLabelWidth" prop="leaseholder" style="width: 600px;">
                            <el-input v-model="deviceInfo.leaseholder" autocomplete="off"  placeholder="请输入租赁人"  maxlength="20" show-word-limit style="width: 220px;"></el-input>
                        </el-form-item>
                        <el-form-item label="租赁费用" :label-width="formLabelWidth" prop="leaseePrice">
                            <el-input type="number" v-model="deviceInfo.leaseePrice" class="wh-input"
                                autocomplete="off"  placeholder="请输入租赁费用"></el-input>
                        </el-form-item>
                        <el-form-item label="租赁日期" :label-width="formLabelWidth" prop="leaseeDate">
                            <el-date-picker v-model="deviceInfo.leaseeDate" format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd" type="date" placeholder="选择租赁日期">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <el-form-item label="配置分析" :label-width="formLabelWidth" prop="deployAnalyst" style="width: 600px;">
                        <el-input v-model="deviceInfo.deployAnalyst" maxlength="100" show-word-limit autocomplete="off"
                            class="input_num" placeholder="请输入配置分析"></el-input>
                    </el-form-item>
                    <el-form-item label="购置需求" :label-width="formLabelWidth" prop="purchaseDemand" style="width: 600px;">
                        <el-input v-model="deviceInfo.purchaseDemand" maxlength="100" show-word-limit autocomplete="off"
                            class="input_num" placeholder="请输入购置需求"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="remark" style="width: 600px;">
                        <el-input v-model="deviceInfo.remark" autocomplete="off"  maxlength="100" show-word-limit placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button  class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
import uploadImg from '@/components/UploadImg/index'
import uploadImg2 from '@/views/device/components/index'
import { uploadFile } from '@/api/file'
import { queryDevice, addDevice, updateDevice, deviceStatusList } from '@/api/device'
import { getListDeviceClass } from '@/api/deviceClass.js'
import { deviceRule } from '@/utils/vaildate.js'
import { getDeviceKeeps } from '@/api/deviceKeep.js'
import { getHouseAll } from '@/api/house.js'
export default {
    components: {
        uploadImg,
        uploadImg2
    },
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            deviceNameList: [],
            deviceClassList: [],
            houseList: [],
            Props: { value: 'subClassCode', label: 'subClassDesc' },
            isCheckoutOk: 0,
            deviceInfo: {
                deviceName: '',
                deviceRealName: '',
                deviceCode: '',
                deviceOneClass: null,
                deviceClassCode: null,
                optionValue: null,
                deviceType: '',
                deviceNum: '',
                purchaseDate: '',
                manufacturer: '',
                deviceinfoImg: '',
                isCheckoutOk: 0,
                xyTag: null,
                xyCer: '',
                xyCerPath: null,
                deviceStatus: '00',
                isAttachment: 0,
                attachmentImg: '',
                isCheck: 0,
                lastCheckDate: '',
                checkCycle: '',
                checkDate: '',
                isMaintain: 0,
                lastMaintainDate: '',
                maintainCycle: '',
                deviceFun: '',
                deployAnalyst: null,
                purchaseDemand: null,
                lastWxDate: null,
                whCode: null,
                location: '',
                source: 0,
                buyer: '',
                purchasePrice: '',
                lender: '',
                borrower: '',
                borrowDate: '',
                leasee: '',
                leaseholder: '',
                leaseePrice: '',
                leaseeDate: '',
                remark: ''
            },
            tagLabel: '合格标签',
            cerLabel: '合格证书',
            rules: deviceRule,
            formLabelWidth: '120px',
            imgsList: [],
            imageView: true,
            attachList: [],
            attachView: true,
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false

        }
    },
    mounted() {
        this.loadDeviceNames()
        this.loadDeviceClass()
        this.loadHouses()
        this.id && this.loadDeviceInfo()
    },
    methods: {

        async loadHouses() {
            await getHouseAll().then(res => {
                if (res.code === '000000') {
                    this.houseList = res.t
                }
            })
        },

        async loadDeviceClass() {
            await getListDeviceClass().then(res => {
                this.deviceClassList = res.t

            })
        },
        async loadDeviceNames() {
            await getDeviceKeeps().then(res => {
                if (res.code === '000000') {
                    this.deviceNameList = res.t
                }
            })
        },

        //根据id查询设备信息
        async loadDeviceInfo() {
            await queryDevice(this.id).then(res => {
                if (res.code === '000000') {
                    this.deviceInfo = res.t
                    if (this.deviceInfo.attachmentImg != null && this.deviceInfo.attachmentImg != '') {
                        const list1 = this.deviceInfo.attachmentImg.split(";")
                        list1.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = {
                                    imageName: item
                                }
                                this.attachList.push(obj)
                            }
                        })
                    }
                    if (this.deviceInfo.deviceinfoImg != null && this.deviceInfo.deviceinfoImg != '') {
                        const list2 = this.deviceInfo.deviceinfoImg.split(";")
                        list2.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = {
                                    imageName: item
                                }
                                this.imgsList.push(obj)
                            }
                        })
                    }
                    if(this.imgsList.length>3){
                        this.imageView = false;
                    }
                }
            })
        },

        //提交
        submitForm() {
            this.deviceInfo.isCheckoutOk = this.isCheckoutOk
            this.$refs.deviceInfo.validate((valid) => {
                if (valid) {

                    this.deviceInfo.deviceinfoImg = '';
                    this.imgsList.forEach(item => {
                        this.deviceInfo.deviceinfoImg += (item.imageName + ";")
                    })
                    // console.log()

                    this.deviceInfo.attachmentImg = '';
                    this.attachList.forEach(item => {
                        this.deviceInfo.attachmentImg += (item.imageName + ";")
                    })
                    if (this.id) {
                        updateDevice(this.deviceInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {

                        if (this.deviceInfo.isAttachment == 1) {
                            this.deviceInfo.attachmentImg = ''
                        }
                        if (this.deviceInfo.isCheck == 1) {
                            this.deviceInfo.lastCheckDate = ''
                            this.deviceInfo.checkCycle = null
                            this.deviceInfo.checkDate = ''
                        }
                        if (this.deviceInfo.isMaintain == 1) {
                            this.deviceInfo.lastMaintainDate = ''
                            this.deviceInfo.maintainCycle = null
                            this.deviceInfo.checkDate = ''
                        }

                        addDevice(this.deviceInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.spareClassInfo.resetFields()
        },

        handleUploadFile(file) {
            const formData = new FormData()
            formData.append('file', file.file)

            uploadFile(formData).then(res => {
                if (res.code === '000000') {
                    this.imageUrl = res.data.importFileName
                    const obj = {
                        imageName: res.data.importFileName
                    }
                    this.imgsList.push(obj)
                    // this.$emit('imgVal', res.data.path)
                    this.deviceInfo.deviceinfoImg = '';
                    this.imgsList.forEach(item => {
                        this.deviceInfo.deviceinfoImg += (item.imageName + ";")
                    })
                    if (this.imgsList.length > 3) {
                        this.imageView = false;
                    }

                }
            })
        },
        handleDel(item, index) {
            this.imgsList.splice(index, 1)
            this.imageView = true
        },
        // 图片上传后地址
        imgVal(val, item) {
            item.imageName = val
        },

        // 上传的图片
        updateImg(val, item) {
            console.log(item)
            console.log(val)
            this.deviceInfo[item] = val
            console.log("this.deviceInfo.xyTag")
            console.log(this.deviceInfo.xyTag)
            // this.deviceInfo.xyCer
            // console.log(this.deviceInfo.xyCer)

        },

        //上传文件
        //点击导入
        handleIn() {
            this.$refs.import.click();
        },
        //上传文件
        uploadIn(event) {
            console.log('上传文件')
            let file = event.target.files; //获得文件
            for (let i = 0; i < file.length; i++) {
                //    上传类型判断
                let fileName = file[i].name;
                let idx = fileName.lastIndexOf(".");
                if (idx != -1) {
                    let ext = fileName.substr(idx + 1).toUpperCase();
                    ext = ext.toLowerCase();
                    if (ext !== "pdf") {
                        this.$message.warning('上传文件只能是pdf文件!')
                        return;
                    } else {
                        this.importFile = file[i];
                    }
                }
            }
            let formData = new FormData(); //数据
            formData.append("file", this.importFile);
            uploadFile(formData)
                .then(res => {
                    if (res.code === '000000') {
                        this.deviceInfo.xyCerPath = res.data.importFileName
                        this.deviceInfo.xyCer = res.data.fileName
                        console.log(this.deviceInfo.xyCer)
                        console.log(this.deviceInfo.xyCerPath)
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },

    },
    watch: {
        isCheckoutOk: function () {
            this.isCheckoutOk === 0 ? this.tagLabel = '合格标签' : this.tagLabel = '不合格标签'
            this.isCheckoutOk === 0 ? this.cerLabel = '合格证书' : this.cerLabel = '不合格证书'
        }
    }
}

</script>


<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        text-align: left;
    }

    .input-lenght {
        width: 500px;
    }

    .upload1 {
        width: 360px;
        height: 240px;
        margin-right: 20px;
        margin-bottom: 20px;

        .el-upload-dragger,
        .el-upload--text,
        .upload-demo1 {
            width: 100%;
            height: 100%;
        }

        .el-upload-dragger .el-icon-upload {
            margin-top: 60px;
        }

        .el-upload__text {
            margin-top: 20px;
        }
    }

    .bannerbox {
        display: flex;
        flex-wrap: wrap;
    }

    .banners {
        width: 360px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        .cha {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            z-index: 2;
        }

        .el-upload__tip {
            margin-top: 7px;
        }

        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            border: none;
            border-radius: 0;
        }
    }

    .input-upload {
        width: 500px;
        position: relative;

        .el-button {
            position: absolute;
            top: 0px;
            right: -100px;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .btns {
        margin-top: 50px;
    }

    .upload-demo {
        width: 360px;
        height: 200px;
    }

    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .wh-input {
        width: 220px;
    }

}</style>